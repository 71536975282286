<template>
    <div class="download-csv">
        <h2>Download CSV</h2>
        <p>Select which columns you want to download for {{records.length}} records.</p>
        <div class="form-group"> 
            <label><input type="checkbox" v-model="includeHeader" /><span>Include header row</span></label>
        </div>
        <div class="form-group" v-for="(column, prop) in columns" :key="prop"> 
            <label><input type="checkbox" v-model="options[prop]" /><span>{{column.title}}</span></label>
        </div>
        <div class="form-controls">
            <button class="button-2" @click="cancel">Cancel</button>
            <button @click="download" :disabled="!Object.values(options).some(x => x)"><i class="fa-regular fa-download"></i> Download</button>
        </div>
    </div>
</template>

<script>
import { reactive, ref } from '@vue/reactivity';
import { onBeforeMount } from '@vue/runtime-core';
import Collection from '../../models/Collection';
import FormatDataMixin from '../../mixins/FormatData'

    export default {
        props: [
            'columns',
            'records',
            'model'
        ],
        setup(props, { emit }) {
            const { output } = FormatDataMixin();
            const options = reactive({});
            const includeHeader = ref(true);

            onBeforeMount(() => {
                for (var col in props.columns){
                    options[col] = true;
                }
            });

            const close = () => {
                emit("close");
            }
            const cancel = () => {
                emit("close");
            }
            const download = () => {
                const collection = new Collection(props.model);
                collection.load(props.records).then(() => {
                    console.log(collection);
                    let selectedColumns = Object.entries(options).filter(x => x[1]);

                    let rows = [];

                    //get all options where checked
                    if (includeHeader.value){
                        let header = [];
                        selectedColumns.forEach(o => header.push(props.columns[o[0]].title));
                        rows.push(header);
                    }

                    //select those options from each row in collection
                    collection.select((dataRow) => {
                        var row = [];
                        selectedColumns.forEach(column => {
                            row.push(
                                output(
                                    dataRow,
                                    0,
                                    column[1],
                                    column[0]
                                )
                            );
                        });
                        rows.push(row);
                    });

                    let data = rows.map(row => {
                        return row
                        //string all values
                        .map(String)
                        //escape double-quotes
                        .map(x => x.replaceAll('"', '""'))
                        //quote values
                        .map(x => `"${x}"`)
                        .join(',')
                    }).join('\r\n');

                    var blob = new Blob([data], {type: 'text/csv;charset=utf-8;'});
                    var url = URL.createObjectURL(blob);

                    var pom = document.createElement('a');
                    pom.href = url;
                    pom.setAttribute('download', 'DATACSV.csv');
                    pom.click();

                });

            }

            return {
                options, 
                includeHeader,

                close,
                cancel,
                download,
            }
        },
        emits: [
            'close'
        ],
    }
</script>

<style lang="scss">

@import "../../assets/scss/variables.scss";
</style>