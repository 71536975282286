<template>
<div class="equipment-list">
    <list-table-layout 
        v-if="loaded"
        :header="`Equipment (${equipment.length})`"
        :columns="columns"
        :model="EquipmentModel"
        :records="equipment"
        :rowControlsWidth="1"
        >
        <template v-slot:openable="{record}">
            <div class="components">
                <div class="component">
                    <header>
                        <h3>Specifications</h3>
                    </header>
                    <equipment-specification :equipment="record"></equipment-specification>
                </div>

                <!-- <div class="component">
                    <header>
                        <h3>Contracts and Compliance</h3>
                    </header>
                    <equipment-contract :contract="row['compliance']"></equipment-contract>
                </div>  -->

                <!-- <div class="component" v-if="row.gps">
                    <header>
                        <h3>GPS</h3> 
                    </header>
                    <equipment-gps :gps="gpsData"></equipment-gps>
                </div> -->
                
                <!-- <div class="component maintenance">
                    <header>
                        <h3>Service History</h3>
                    </header>
                    <equipment-service-history :serviceRequests="row.serviceRequests"></equipment-service-history>
                </div> -->
            </div>
        </template>
        <template v-slot:table-controls>
            <button @click="download"><i class="fa-regular fa-download"></i> Download</button>
        </template>
        <template v-slot:rowcontrols="{record}">
            <router-link class="button" :to="`/equipment/${record.id}`"><i class="fa-regular fa-file-magnifying-glass"></i></router-link>
        </template>
    </list-table-layout>
    <overlay v-if="overlay" @close="overlay = false">
        <template v-slot:content>
            <download-csv :columns="columns" :records="equipment" :model="EquipmentModel" @close="overlay = false"></download-csv>
        </template>
    </overlay>
</div>
</template>

<script>
import ListTableLayout from '../../components/layouts/ListTableLayout.vue'
import EquipmentSpecification from '../../components/equipment/EquipmentSpecification.vue';
// import EquipmentContract from '../../components/equipment/EquipmentContract.vue';
// import EquipmentGps from '../../components/equipment/EquipmentGPS.vue';
// import EquipmentServiceHistory from '../../components/equipment/EquipmentServiceHistory.vue';

import EquipmentModel from '../../models/EquipmentModel.js';

import DoorType from '../../models/DoorType';
import WallType from '../../models/WallType';
import SuspensionType from '../../models/SuspensionType';

import Overlay from '../../components/utilities/Overlay.vue';
import DownloadCsv from '../../components/utilities/DownloadCsv.vue';
import { ref } from '@vue/reactivity';
import database from "@/store/database";


const columns = {
    'trailerNumber' : {
        title : "Trailer #",
        sortable: true,
        width: 2,
    },
    'year' : {
        title: "Year",
        sortable: true,
        width: 1,
    },
    'make.name' : {
        title: "Make",
        sortable: true,
        width: 2,
    },
    'nominalLength' : {
        title: "Len.",
        sortable: true,
        format: "{nominalLength}'",
        width: 1,
    },
    'description' : {
        title: "Desc.",
        sortable: true,
        width: 2,
    },
    'doorType' : {
        title: "Door Type",
        sortable: true,
        options: DoorType,
        width: 2,
    },
    'suspensionType' : {
        title: "Suspension",
        sortable: true, 
        options: SuspensionType,
        width: 2,
    },
    'wallType' : {
        title: "Wall Type",
        sortable: true,
        options: WallType,
        width: 2
    },
    // 'gps' : {
    //     title: "GPS",
    //     sortable: true,
    //     true: 'Yes',
    //     false: 'No'
    // }

};

export default {
    components: {
        ListTableLayout,
        EquipmentSpecification,
        Overlay,
        DownloadCsv,
        // EquipmentServiceHistory,
        // EquipmentContract,
        // EquipmentGps,
        // EquipmentMaintenance
    },
    setup(){
        const promises = [];
        const loaded = ref(false);

        const equipment = database[EquipmentModel.name].list;

        Promise.all(promises).then(() => {
            loaded.value = true;
        });

        const overlay = ref(false);
        return {equipment, loaded, EquipmentModel, columns, overlay}

    },
    methods: {
        download(){
            this.overlay = true;
        },
        cancel(){
            this.overlay = false;
        }
    }
}
</script>
<style lang="scss">

@import "../../assets/scss/variables.scss";
.equipment-list{
    .maintenance{
        min-width: 50%;

        .simple-table{
            width: calc(100% + #{$space-wide * 2});
        }
    }
    .component{
        .headless-table{
            width: 100%;
        }
    }
}

@media (min-width: 1500px) and (max-width: $break-wide){
    .equipment-list{
        .simple-table{
            .component:not(.maintenance) {
                width: calc((100% - (#{$space-standard} * 6)) / 3);
                flex: 0 0 auto;
            }
        }
    }
}

</style>